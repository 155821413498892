export const WORKSHOP_CAROUSEL = [
    'https://firebasestorage.googleapis.com/v0/b/dragana-jevtovic.appspot.com/o/home%2Fstaff%2FDSCF4271a.jpg?alt=media&token=9a1cc08d-c700-44cc-a949-bd35f1a770aa',
    'https://firebasestorage.googleapis.com/v0/b/dragana-jevtovic.appspot.com/o/home%2Fstaff%2FDSCF4274a.jpg?alt=media&token=0600e9a1-c662-46b0-ac84-80a8fb54f1ce',
    'https://firebasestorage.googleapis.com/v0/b/dragana-jevtovic.appspot.com/o/home%2Fstaff%2FDSCF4281a.jpg?alt=media&token=07f4aa87-70b4-4688-8fac-43f08692409a',
    'https://firebasestorage.googleapis.com/v0/b/dragana-jevtovic.appspot.com/o/home%2Fstaff%2FDSCF4300.jpg?alt=media&token=aac524f9-8e70-41bf-b50b-2bd29f308f42',
    'https://firebasestorage.googleapis.com/v0/b/dragana-jevtovic.appspot.com/o/home%2Fstaff%2FDSCF4313a.jpg?alt=media&token=dfbe40e5-c249-4e45-9a95-f51be9e983f8',
    'https://firebasestorage.googleapis.com/v0/b/dragana-jevtovic.appspot.com/o/home%2Fstaff%2FDSCF4334a.jpg?alt=media&token=dba84af7-3164-485e-bb74-1c0d94084c4c',
    'https://firebasestorage.googleapis.com/v0/b/dragana-jevtovic.appspot.com/o/home%2Fstaff%2FDSCF4339.jpg?alt=media&token=86e54075-2702-498f-8adc-71bdc30dad62',
    'https://firebasestorage.googleapis.com/v0/b/dragana-jevtovic.appspot.com/o/home%2Fstaff%2FDSCF4348.jpg?alt=media&token=34c3940d-f6d5-46d6-bd42-0de368df838a',
    'https://firebasestorage.googleapis.com/v0/b/dragana-jevtovic.appspot.com/o/home%2Fstaff%2FDSCF4361.jpg?alt=media&token=8f8e3dd6-d3bf-45e9-8145-ff81a03f4c41',
    'https://firebasestorage.googleapis.com/v0/b/dragana-jevtovic.appspot.com/o/home%2Fstaff%2FDSCF4382no.jpg?alt=media&token=9dc3b19d-e7e9-4c78-a77d-dab0708eeed2',
    'https://firebasestorage.googleapis.com/v0/b/dragana-jevtovic.appspot.com/o/home%2Fstaff%2FDSCF4388.jpg?alt=media&token=11992c80-a272-4f58-9a02-39ea5d34a40a',
    'https://firebasestorage.googleapis.com/v0/b/dragana-jevtovic.appspot.com/o/home%2Fstaff%2FDSCF4410.jpg?alt=media&token=5ec7c5ec-92c4-4e57-8803-0a407b6bdb45'
];